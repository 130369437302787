import React, { useLayoutEffect, useMemo, useState } from "react"
import Container from "../container/container"
import HeadSubhead from "../headsubhead/headsubhead"
import "./techstacks.scss"
import Button from "../button/button"
import Image from "../../components/image-component/image-2023"
export default function TechStacks({
  version = "v1",
  heading = "Experience across tech stacks",
}) {
  const [activeStack, setActiveStack] = useState("popular")

  useLayoutEffect(() => {
    const stackButton = document.querySelector(
      ".stack-btns .btn-wrapper .btn-component"
    )
    const leslide = document.querySelector(".movable-background")
    leslide.style.maxWidth = stackButton.offsetWidth + "px"
  }, [])

  const stackButtons = useMemo(() => {
    return [
      {
        label: "Popular",
        id: "popular",
      },
      {
        label: "Testing",
        id: "testing",
      },
      {
        label: "CMS",
        id: "cms",
      },
      {
        label: "Dev",
        id: "dev",
      },
      {
        label: "Analytics",
        id: "analytics",
      },
      {
        label: "Martech",
        id: "martech",
      },
    ]
  }, [])

  const stackContent = useMemo(() => {
    let stackImages = [
      {
        imageName: "2023-optimizely-logo.webp",
        imageAlt: "Optimizely logo",
        imageTag: "popular",
      },
      {
        imageName: "2023-optimizely-logo.webp",
        imageAlt: "Optimizely logo",
        imageTag: "testing",
      },
      {
        imageName: "2023-vwo-logo.webp",
        imageAlt: "VWO logo",
        imageTag: "popular",
      },
      {
        imageName: "2023-vwo-logo.webp",
        imageAlt: "VWO logo",
        imageTag: "testing",
      },
      {
        imageName: "2023-convertkit-logo.webp",
        imageAlt: "convertkit logo",
        imageTag: "popular",
      },
      {
        imageName: "2023-convertkit-logo.webp",
        imageAlt: "convertkit logo",
        imageTag: "testing",
      },
      {
        imageName: "2023-abtasty-logo.webp",
        imageAlt: "abtasty logo",
        imageTag: "popular",
      },
      {
        imageName: "2023-abtasty-logo.webp",
        imageAlt: "abtasty logo",
        imageTag: "testing",
      },
      {
        imageName: "2023-adobe-logo.webp",
        imageAlt: "adobe logo",
        imageTag: "popular",
      },
      {
        imageName: "2023-adobe-logo.webp",
        imageAlt: "adobe logo",
        imageTag: "testing",
      },
      {
        imageName: "2023-adobe-logo.webp",
        imageAlt: "adobe logo",
        imageTag: "cms",
      },
      {
        imageName: "2023-marketologo.webp",
        imageAlt: "marketo logo",
        imageTag: "popular",
      },
      {
        imageName: "2023-hubspot-logo.webp",
        imageAlt: "hubspot logo",
        imageTag: "popular",
      },
      {
        imageName: "2023-hubspot-logo.webp",
        imageAlt: "hubspot logo",
        imageTag: "cms",
      },
      {
        imageName: "2023-hubspot-logo.webp",
        imageAlt: "hubspot logo",
        imageTag: "martech",
      },
      {
        imageName: "2023-pardot-logo.webp",
        imageAlt: "pardot logo",
        imageTag: "popular",
      },
      {
        imageName: "2023-salesforce-logo.webp",
        imageAlt: "salesforce logo",
        imageTag: "martech",
      },
      {
        imageName: "2023-salesforce-logo.webp",
        imageAlt: "salesforce logo",
        imageTag: "popular",
      },
      {
        imageName: "2023-go-logo.webp",
        imageAlt: "google optimize logo",
        imageTag: "popular",
      },
      {
        imageName: "2023-go-logo.webp",
        imageAlt: "google optimize logo",
        imageTag: "testing",
      },
      {
        imageName: "2023-wordpresslogo.webp",
        imageAlt: "wordpress logo",
        imageTag: "popular",
      },
      {
        imageName: "2023-wordpresslogo.webp",
        imageAlt: "wordpress logo",
        imageTag: "cms",
      },
      {
        imageName: "2023-unbounce-logo.webp",
        imageAlt: "unbounce logo",
        imageTag: "popular",
      },
      {
        imageName: "2023-unbounce-logo.webp",
        imageAlt: "unbounce logo",
        imageTag: "cms",
      },
      {
        imageName: "2023-instapage-logo.webp",
        imageAlt: "instapage logo",
        imageTag: "popular",
      },
      {
        imageName: "2023-instapage-logo.webp",
        imageAlt: "instapage logo",
        imageTag: "cms",
      },
      {
        imageName: "2023-contentful-logo.webp",
        imageAlt: "contentful logo",
        imageTag: "cms",
      },
      {
        imageName: "2023-contentful-logo.webp",
        imageAlt: "contentful logo",
        imageTag: "popular",
      },
      {
        imageName: "2023-react-logo.webp",
        imageAlt: "react logo",
        imageTag: "popular",
      },
      {
        imageName: "2023-react-logo.webp",
        imageAlt: "react logo",
        imageTag: "dev",
      },
      {
        imageName: "2023-mutiny-logo.webp",
        imageAlt: "mutiny logo",
        imageTag: "testing",
      },
      {
        imageName: "2023-launchdarkly-logo.webp",
        imageAlt: "launchdarkly logo",
        imageTag: "testing",
      },
      {
        imageName: "2023-monetate-logo.webp",
        imageAlt: "monetate logo",
        imageTag: "testing",
      },
      {
        imageName: "2023-maxymiser-logo.webp",
        imageAlt: "maxymiser logo",
        imageTag: "testing",
      },
      {
        imageName: "2023-amplitude-logo.webp",
        imageAlt: "amplitude logo",
        imageTag: "testing",
      },
      {
        imageName: "2023-amplitude-logo.webp",
        imageAlt: "amplitude logo",
        imageTag: "analytics",
      },
      {
        imageName: "2023-intellimize-logo.webp",
        imageAlt: "intellimize logo",
        imageTag: "testing",
      },
      {
        imageName: "2023-convert-logo.webp",
        imageAlt: "convert logo",
        imageTag: "testing",
      },
      {
        imageName: "2023-omniconvert-logo.webp",
        imageAlt: "omniconvert logo",
        imageTag: "testing",
      },
      {
        imageName: "2023-kameleoon-logo.webp",
        imageAlt: "kameleoon logo",
        imageTag: "testing",
      },
      {
        imageName: "2023-webflow-logo.webp",
        imageAlt: "webflow logo",
        imageTag: "cms",
      },
      {
        imageName: "2023-marketocms-logo.webp",
        imageAlt: "marketocms logo",
        imageTag: "martech",
      },
      {
        imageName: "2023-marketocms-logo.webp",
        imageAlt: "marketocms logo",
        imageTag: "cms",
      },
      {
        imageName: "2023-drupal-logo.webp",
        imageAlt: "drupal logo",
        imageTag: "cms",
      },
      {
        imageName: "2023-netlify-logo.webp",
        imageAlt: "netlify logo",
        imageTag: "cms",
      },
      {
        imageName: "2023-shopify-logo.webp",
        imageAlt: "shopify logo",
        imageTag: "cms",
      },
      {
        imageName: "2023-magento-logo.webp",
        imageAlt: "magento logo",
        imageTag: "cms",
      },
      {
        imageName: "2023-kentico-logo.webp",
        imageAlt: "kentico logo",
        imageTag: "cms",
      },
      {
        imageName: "2023-progress-logo.webp",
        imageAlt: "progress logo",
        imageTag: "cms",
      },
      {
        imageName: "2023-sitecore-logo.webp",
        imageAlt: "sitecore logo",
        imageTag: "cms",
      },
      {
        imageName: "2023-html-logo.webp",
        imageAlt: "html logo",
        imageTag: "dev",
      },
      {
        imageName: "2023-css-logo.webp",
        imageAlt: "css logo",
        imageTag: "dev",
      },
      {
        imageName: "2023-js-logo.webp",
        imageAlt: "js logo",
        imageTag: "dev",
      },
      {
        imageName: "2023-jquery-logo.webp",
        imageAlt: "jquery logo",
        imageTag: "dev",
      },
      {
        imageName: "2023-nodejs-logo.webp",
        imageAlt: "nodejs logo",
        imageTag: "dev",
      },
      {
        imageName: "2023-jamstack-logo.webp",
        imageAlt: "jamstack logo",
        imageTag: "dev",
      },
      {
        imageName: "2023-angular-logo.webp",
        imageAlt: "angular logo",
        imageTag: "dev",
      },
      {
        imageName: "2023-github-logo.webp",
        imageAlt: "github logo",
        imageTag: "dev",
      },
      {
        imageName: "2023-php-logo.webp",
        imageAlt: "php logo",
        imageTag: "dev",
      },
      {
        imageName: "2023-bitbucket-logo.webp",
        imageAlt: "bitbucket logo",
        imageTag: "dev",
      },
      {
        imageName: "2023-aws-logo.webp",
        imageAlt: "aws logo",
        imageTag: "dev",
      },
      {
        imageName: "2023-d3js-logo.webp",
        imageAlt: "d3js logo",
        imageTag: "dev",
      },
      {
        imageName: "2023-mongodb-logo.webp",
        imageAlt: "mongodb logo",
        imageTag: "dev",
      },
      {
        imageName: "2023-mysql-logo.webp",
        imageAlt: "mysql logo",
        imageTag: "dev",
      },
      {
        imageName: "2023-ga-logo.webp",
        imageAlt: "google analytics logo",
        imageTag: "analytics",
      },
      {
        imageName: "2023-heap-logo.webp",
        imageAlt: "heap logo",
        imageTag: "analytics",
      },
      {
        imageName: "2023-hotjar-logo.webp",
        imageAlt: "hotjar logo",
        imageTag: "analytics",
      },
      {
        imageName: "2023-crazyegg-logo.webp",
        imageAlt: "crazyegg logo",
        imageTag: "analytics",
      },
      {
        imageName: "2023-fullstory-logo.webp",
        imageAlt: "fullstory logo",
        imageTag: "analytics",
      },
      {
        imageName: "2023-glassbox-logo.webp",
        imageAlt: "glassbox logo",
        imageTag: "analytics",
      },
      {
        imageName: "2023-mixpanel-logo.webp",
        imageAlt: "mixpanel logo",
        imageTag: "analytics",
      },
      {
        imageName: "2023-wynter-logo.webp",
        imageAlt: "wynter logo",
        imageTag: "analytics",
      },
      {
        imageName: "2023-adobeanalytics-logo.webp",
        imageAlt: "adobe analytics logo",
        imageTag: "analytics",
      },
      {
        imageName: "2023-kissmetrics-logo.webp",
        imageAlt: "kissmetrics logo",
        imageTag: "analytics",
      },
      {
        imageName: "2023-gtm-logo.webp",
        imageAlt: "google tag manager logo",
        imageTag: "analytics",
      },
      {
        imageName: "2023-twiliosegment-logo.webp",
        imageAlt: "twilio segment logo",
        imageTag: "analytics",
      },
      {
        imageName: "2023-usertesting-logo.webp",
        imageAlt: "usertesting logo",
        imageTag: "analytics",
      },
      {
        imageName: "2023-usabilityhub-logo.webp",
        imageAlt: "usability hub logo",
        imageTag: "analytics",
      },
      {
        imageName: "2023-demandbase-logo.webp",
        imageAlt: "demandbase logo",
        imageTag: "martech",
      },
      {
        imageName: "2023-drift-logo.webp",
        imageAlt: "drift logo",
        imageTag: "martech",
      },
      {
        imageName: "2023-intercom-logo.webp",
        imageAlt: "intercom logo",
        imageTag: "martech",
      },
      {
        imageName: "2023-clearbit-logo.webp",
        imageAlt: "clearbit logo",
        imageTag: "martech",
      },
      {
        imageName: "2023-6sense-logo.webp",
        imageAlt: "6sense logo",
        imageTag: "martech",
      },
      {
        imageName: "2023-typeform-logo.webp",
        imageAlt: "typeform logo",
        imageTag: "martech",
      },
      {
        imageName: "2023-onetrust-logo.webp",
        imageAlt: "onetrust logo",
        imageTag: "martech",
      },
      {
        imageName: "2023-stripe-logo.webp",
        imageAlt: "stripe logo",
        imageTag: "martech",
      },
      {
        imageName: "2023-fastspring-logo.webp",
        imageAlt: "fastspring logo",
        imageTag: "martech",
      },
      {
        imageName: "2023-recurly-logo.webp",
        imageAlt: "recurly logo",
        imageTag: "martech",
      },
      {
        imageName: "2023-chilipiper-logo.webp",
        imageAlt: "chili piper logo",
        imageTag: "martech",
      },
      {
        imageName: "2023-calendly-logo.webp",
        imageAlt: "calendly logo",
        imageTag: "martech",
      },
    ]
    return stackImages.filter(stack => stack.imageTag === activeStack)
  }, [activeStack])

  return (
    <Container className={`teck-stacks-wrapper pd-112 ${version}`}>
      <HeadSubhead
        version={version}
        heading={heading}
        subheading={`We work with your existing tech stack.  Need something &ndash;  we’ll pay for it on our dime.`}
      ></HeadSubhead>
      <div className="stack-btns">
        <div className="movable-background"> </div>
        {stackButtons.map((stack, index) => {
          return (
            <Button
              className={`btn-component ${
                stack.id === activeStack ? `active` : ``
              }`}
              version={version}
              onClick={e => {
                var leslide = document.querySelector(".movable-background")
                leslide.style.maxWidth = e.currentTarget.offsetWidth + "px"
                leslide.animate(
                  [
                    {
                      transform:
                        "translateX(" + e.currentTarget.offsetLeft + "px)",
                    },
                  ],
                  {
                    duration: 300,
                    easing: "ease-out",
                    fill: "both",
                  }
                )
                setActiveStack(stack.id)
              }}
              key={index}
            >
              {stack.label}
            </Button>
          )
        })}
      </div>

      <div className="stack-logos">
        {stackContent.map((item, index) => {
          return (
            <div key={index} className="stack-logo">
              <Image
                sourceFolder={"homepage"}
                imgName={item.imageName}
                className={"customer-logo"}
                alt={item.imageAlt}
                lazyLoad={"true"}
              />
            </div>
          )
        })}
      </div>
    </Container>
  )
}

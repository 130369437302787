import React, { useCallback, useEffect } from "react"
export default function TeamRotator({
  children,
  windowWidth,
  teamMember,
  ...props
}) {
  const teamCircleWidth = useCallback(
    (windowWidth, teamStyle) => {
      if (windowWidth < 767) {
        document.querySelector(
          `#${teamMember?.alt}`
        ).style.width = `${teamStyle?.mobileStyle?.width}`
        document.querySelector(
          `#${teamMember?.alt}`
        ).style.height = `${teamStyle?.mobileStyle?.height}`
        document.querySelector(
          `#${teamMember?.alt}`
        ).style.minWidth = `${teamStyle?.mobileStyle?.minWidth}`
        return
      } else if (windowWidth > 767 && windowWidth < 1360) {
        document.querySelector(
          `#${teamMember?.alt}`
        ).style.width = `${teamStyle?.tabletStyle?.width}`
        document.querySelector(
          `#${teamMember?.alt}`
        ).style.height = `${teamStyle?.tabletStyle?.height}`
        document.querySelector(
          `#${teamMember?.alt}`
        ).style.minWidth = `${teamStyle?.tabletStyle?.minWidth}`
        return
      }
      document.querySelector(
        `#${teamMember?.alt}`
      ).style.width = `${teamStyle?.desktopStyle?.width}`
      document.querySelector(
        `#${teamMember?.alt}`
      ).style.height = `${teamStyle?.desktopStyle?.height}`
      document.querySelector(
        `#${teamMember?.alt}`
      ).style.minWidth = `${teamStyle?.desktopStyle?.minWidth}`
      return
    },
    [windowWidth]
  )

  useEffect(() => {
    teamCircleWidth(windowWidth, teamMember)
  }, [teamCircleWidth, windowWidth, teamMember])

  return (
    <div id={`${teamMember?.alt}`} {...props}>
      {" "}
      {children}{" "}
    </div>
  )
}

import React, { useEffect } from "react"
import "./colorprogressbar.scss"

export default function ColorProgressBar({
  text,
  value,
  version = "v1",
  progressWidth = 100,
  ...props
}) {
  const isSSR = typeof document !== "undefined"
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.maxWidth =
              entry.target.getAttribute("aria-valuenow") + "%"
          } else {
            entry.target.style.maxWidth = "0%"
          }
        })
      },
      { threshold: 0.5 }
    )
    const elements = document.querySelectorAll(".colored-bar")
    elements.forEach(element => {
      observer.observe(element)
    })
    return () => {
      elements.forEach(element => {
        observer.unobserve(element)
      })
    }
  }, [isSSR])

  return (
    <div className={`color-progress-bar-wrapper ${version}`} {...props}>
      <div className="color-progress-bar-inner">
        {value && <div className="color-progress-bar-value">{value}</div>}
        <div className="color-progress-bar-text">
          {text && <span className="colored-label">{text}</span>}
          <div className="bar-track">
            <span className="colored-bar" aria-valuenow={progressWidth}>
              {" "}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

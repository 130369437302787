import React, { useCallback, useEffect, useMemo, useState } from "react"
import Container from "../container/container"
import "./howitworks.scss"
import HeadSubhead from "../headsubhead/headsubhead"
import MainHeading from "../mainheading/mainheading"
import Button from "../button/button"
import Image from "../../components/image-component/image-2023"
import TeamRotator from "../teamrotator/teamrotator"
import { ArrowIcon } from "../icons"
import ReactHtmlParser from "react-html-parser"
import ColorProgressBar from "../colorprogressbar/colorprogressbar"
import { navigate } from "gatsby"
export default function HowitWorks({
  version = "v1",
  section1Cta = "Prediction Engine",
  section1Description = "<p>Spiralyze scrapes A/B test data from<br /> the 78,000 sites that actively test to<br /> learn what wins. Machine learning<br /> uses that data to predict what wins on<br /> your sites. Get 3X higher win rates<br /> than legacy A/B testing.</p>",
  section1Image = "howitworksanimimage.png",
  section2Cta = "Learn more",
  section2Description = "<p>Design, copy, dev, QA, analytics, and PM. All software included. First test live in just 2 weeks. All implementation handled by a team of 170 A/B testing specialists.</p>",
  section3Cta = "Performance Pricing",
  section3Heading = "38% lift in 90 days.<br /> No fee until we <br class='hidemobile'/> deliver.",
}) {
  // get viewport width
  const [windowWidth, setWindowWidth] = useState(() => {
    if (typeof window !== "undefined") {
      return Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      )
    }
  })

  const HowItWorksSection2 = ({
    className,
    version,
    heading = " Run More A/B Tests<br /> With Everything Done For You",
  }) => {
    return (
      <div className={`title-and-subtitle ${className}`}>
        <MainHeading
          className="main-heading"
          version={version}
          subHeading={`FULL SERVICE CRO AGENCY`}
        >
          {heading}
        </MainHeading>
        <p>
          <span>
            Design, copy, dev, QA, analytics, and PM. All software included.
            First test live in just 2 weeks.
            <br /> All implementation handled by a team of 160 A/B testing
            specialists.{" "}
          </span>
          <span>
            <Button
              version={version}
              onClick={e => navigate("/full-service-team/")}
              icon={<ArrowIcon />}
              type={`cta`}
            >
              {section2Cta}
            </Button>
          </span>
        </p>
      </div>
    )
  }

  const HowItWorksSectionWithImageAndText = ({ version }) => {
    return (
      <React.Fragment>
        <div className="hiw-sec-left">
          <div className="hiw-absolute-image">
            <Image
              sourceFolder={"homepage"}
              lazyLoad={"true"}
              alt={`How it works`}
              fallBackImage={`Howitworksimage2v2.png`}
              imgName={`Howitworksimage2v2.png`}
            />
          </div>
        </div>
        <div className="hiw-sec-right">
          <MainHeading
            className="main-heading"
            subHeading={`FULL SERVICE CRO AGENCY`}
          >
            Run more A/B tests with everything done for you.
          </MainHeading>
          {ReactHtmlParser(section2Description)}
          <Button
            version={version}
            onClick={e => navigate("/how-it-works/")}
            icon={<ArrowIcon />}
            type={`cta`}
          >
            {section2Cta}
          </Button>
        </div>
      </React.Fragment>
    )
  }

  const HowItWorksSectionWithTeam = ({ teamGroups, version }) => {
    return (
      <React.Fragment>
        <div className="team-groups group-1">
          {teamGroups[0].map((teamMember, index) => {
            return (
              <TeamRotator
                windowWidth={windowWidth}
                teamMember={teamMember}
                key={index}
              >
                <Image
                  sourceFolder={"full-team"}
                  lazyLoad={"false"}
                  alt={teamMember?.alt}
                  fallBackImage={teamMember?.name}
                  imgName={teamMember?.name}
                />
              </TeamRotator>
            )
          })}
        </div>

        <HowItWorksSection2 className="desktop" version={version} />

        <div className="team-groups group-2">
          {teamGroups[1].map((teamMember, index) => {
            return (
              <TeamRotator
                windowWidth={windowWidth}
                teamMember={teamMember}
                key={index}
              >
                <Image
                  key={index}
                  sourceFolder={"full-team"}
                  lazyLoad={"false"}
                  alt={teamMember?.alt}
                  fallBackImage={teamMember?.name}
                  imgName={teamMember?.name}
                />
              </TeamRotator>
            )
          })}
        </div>
        <HowItWorksSection2 className="mobile" version={version} />
      </React.Fragment>
    )
  }

  const getWindowWidth = () => {
    if (typeof window !== "undefined") {
      return Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      )
    }
  }

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        setWindowWidth(getWindowWidth())
      },
      false
    )
    return () => {
      window.removeEventListener(
        "resize",
        () => {
          setWindowWidth(getWindowWidth())
        },
        false
      )
    }
  }, [])

  const teamGroups = useMemo(() => {
    return [
      [
        {
          name: "Aleksandra.png",
          alt: "Aleksandra",
          pictureTagIndexes: [],
          desktopStyle: {
            height: "84px",
            width: "84px",
            minWidth: "84px",
          },
          tabletStyle: {
            height: "58px",
            width: "58px",
            minWidth: "58px",
          },
          mobileStyle: {
            height: "46.4px",
            width: "46.4px",
            minWidth: "46.4px",
          },
        },
        {
          name: "Gajan.png",
          alt: "Gajan",
          pictureTagIndexes: [],
          desktopStyle: {
            height: "101px",
            width: "101px",
            minWidth: "101px",
          },
          tabletStyle: {
            height: "70px",
            width: "70px",
            minWidth: "70px",
          },
          mobileStyle: {
            height: "68.12px",
            width: "68.12px",
            minWidth: "68.12px",
          },
        },
        {
          name: "Jefferson.png",
          alt: "Jefferson",
          pictureTagIndexes: [],
          desktopStyle: {
            height: "80px",
            width: "80px",
            minWidth: "80px",
          },
          tabletStyle: {
            height: "55px",
            width: "55px",
            minWidth: "55px",
          },
          mobileStyle: {
            height: "56px",
            width: "56px",
            minWidth: "56px",
          },
        },
      ],
      [
        {
          name: "Nikita.png",
          alt: "Nikita",
          pictureTagIndexes: [],
          desktopStyle: {
            height: "101px",
            width: "101px",
            minWidth: "101px",
          },
          tabletStyle: {
            height: "72px",
            width: "72px",
            minWidth: "72px",
          },
          mobileStyle: {
            width: "47.589px",
            height: "47.589px",
            minWidth: "47.589px",
          },
        },
        {
          name: "Anastasia.png",
          alt: "Anastasia",
          pictureTagIndexes: [],
          desktopStyle: {
            height: "84px",
            width: "84px",
            minWidth: "84px",
          },
          tabletStyle: {
            height: "58px",
            width: "58px",
            minWidth: "58px",
          },
          mobileStyle: {
            height: "41.78px",
            width: "41.78px",
            minWidth: "41.78px",
          },
        },
        {
          name: "Mitko.png",
          alt: "Mitko",
          pictureTagIndexes: [],
          desktopStyle: {
            height: "99.4px",
            width: "99.4px",
            minWidth: "99.4px",
          },
          tabletStyle: {
            height: "66px",
            width: "66px",
            minWidth: "66px",
          },
          mobileStyle: {
            height: "50.23px",
            width: "50.23px",
            minWidth: "50.23px",
          },
        },
        {
          name: "Hannah.png",
          alt: "Hannah",
          pictureTagIndexes: [],
          desktopStyle: {
            height: "126px",
            width: "126px",
            minWidth: "126px",
          },
          tabletStyle: {
            height: "87px",
            width: "87px",
            minWidth: "87px",
          },
          mobileStyle: {
            height: "43px",
            width: "43px",
            minWidth: "43px",
          },
        },
      ],
    ]
  }, [])

  return (
    <Container className={`howitworks-wrapper ${version}`}>
      <div className="howitworks-inner pd-112">
        <HeadSubhead
          version={version}
          heading="How it Works"
          subheading={`See how Predictive CRO gets you bigger and faster wins than legacy CRO. <br class='hidedesktop hidetablet hidemobile'/> Results guaranteed!`}
        />
        <div className="hiw-sec-1">
          <div className="hiw-sec-left">
            <MainHeading
              version={version}
              className="main-heading"
              subHeading={`Prediction Engine`}
            >
              Find proven winners scraped from over 78,000 websites.
            </MainHeading>
            {ReactHtmlParser(section1Description)}
            <Button
              version={version}
              onClick={e => navigate("/prediction-engine/")}
              icon={<ArrowIcon />}
              type={`cta`}
            >
              {section1Cta}
            </Button>
          </div>
          <div className="hiw-sec-right">
            <video autoPlay muted playsInline>
              {/* <source
                  src="//res.cloudinary.com/spiralyze/video/upload/spiralyze2022/VIDEOS/howitworksanimation1.webm"
                  type="video/webm"
                /> */}
              <source
                src="//res.cloudinary.com/spiralyze/video/upload/f_auto/spiralyze2022/VIDEOS/V4final.mp4"
                type="video/mp4"
              />
            </video>

            {/* <Image
              sourceFolder={"how_it_works"}
              lazyLoad={"true"}
              alt={`Proven winners`}
              fallBackImage={section1Image}
              imgName={section1Image}
            /> */}

            {/* <div className="image-components">
              {howItWorksData.map((data, index) => {
                return (
                  <div
                    className={`image-component ${`image-component-${index}`}`}
                    key={index}
                  >
                    <Image
                      sourceFolder={"how_it_works"}
                      lazyLoad={"false"}
                      fallBackImage={data?.image?.name}
                      imgName={data?.image?.name}
                    />
                    <ProgressBar
                      innerWidth={data?.progressBar?.innerWidth}
                      innerColor={data?.progressBar?.innerColor}
                    >
                      {data?.progressBar?.text}
                    </ProgressBar>
                  </div>
                )
              })}
            </div> */}
          </div>
          {/* windowWidth < 1360
                      ? { ...teamMember?.tabletStyle }
                      : { ...teamMember?.desktopStyle } */}
        </div>
        <div className="hiw-sec-2">
          {version === "v1" ? (
            <HowItWorksSectionWithTeam
              teamGroups={teamGroups}
              version={version}
            />
          ) : (
            <HowItWorksSectionWithImageAndText version={version} />
          )}
        </div>
        <div className="hiw-sec-3">
          <div className="hiw-sec-left">
            <div className="color-progress-bars">
              <ColorProgressBar
                version={version}
                text={`Test Launched (in first 90 days)`}
                value={`10`}
                progressWidth={version === "v1" ? 100 : 50}
              />
              <ColorProgressBar
                version={version}
                text={`Days to First Win`}
                value={`25`}
                progressWidth={version === "v1" ? 100 : 80}
              />
              <ColorProgressBar
                version={version}
                text={`Average Lift (in first 90 days)`}
                value={`38%`}
                progressWidth={version === "v1" ? 100 : 95}
              />
            </div>
          </div>
          <div className="hiw-sec-right">
            <MainHeading
              className="main-heading"
              subHeading={`GUARANTEED RESULTS`}
            >
              {section3Heading}
            </MainHeading>
            <p>
              By adapting proven winners we can
              <br /> guarantee results. We don't get paid
              <br /> until after you get the promised lift.
              <br /> You're the referee. 10 tests, 3 wins,
              <br /> &gt;38% lift in your first 90 days.
            </p>
            <Button
              version={version}
              onClick={e => navigate("/pricing/")}
              icon={<ArrowIcon />}
              type={`cta`}
            >
              {section3Cta}
            </Button>
          </div>
        </div>
      </div>
    </Container>
  )
}
